@font-face {
  font-family: "Lora";
  src: local("Lora"),
   url("../fonts/Lora-VariableFont_wght.ttf");
 }


.font-link {
  font-family: 'Raleway', sans-serif;
  
}

header {
  background-color: #F3F2E6;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Gothic', 'GothamMedium'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F2E6;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  color: #f0f0f0;
  font-family: "Lora";
}

h1 {
  color: #494846;
  font-family: "Lora";
}

.NavItem {
  text-decoration: none;
  font-family: "Lora";
  background-color: rgba(0, 0, 0, 0);
  padding: 8px;
  border-radius: 10px;
  border-color: #6B6054;
  border-width: 1px;
  text-wrap: pretty;
  flex-wrap: wrap;

  float: left;
  color: #f2f2f2;
  text-align: center;

  font-size: 17px;
}

.NavItem:hover {
  background-color: #6B6054;
  padding: 8px;
  border-radius: 10px;
}

p {
  font-family: "Lora";
  color: white;
}

.App {
  background-color: #F3F2E6;
}

/* Add a black background color to the top navigation */
.Navbar {
  background-color: #494846;
  overflow: hidden;
  justify-content: center;
  margin: auto;
  text-align: center;
}

.CollegeIsland {
  width: 70%;
  margin: 40px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #494846;
  color: white;
  cursor: pointer;
  transition: min-height 0.4s ease, padding 0.4s ease, background-color 0.4s ease, padding 0.4s ease;
  overflow: hidden;
  min-height: 90px;
}

.CollegeIsland:hover {
  width: 70%;
  margin: 40px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #3d3c3a;
  color: white;
  cursor: pointer;
  overflow: hidden;
}


.CollegeIsland.expanded {
  background-color: #494846;
  min-height: 800px; /* Adjust according to content height */
  padding-bottom: 20px;
}